/**
 * Home JS
 *
 */

import Swal from 'sweetalert2';
import 'slick-carousel/slick/slick';

window.Home = new (function() {

    const that = this;

    this.init = function (){

        try {
            $('.wrapImageDrag').beforeAfter({
                movable: true,
                clickMove: true,
                position: 60,
                separatorColor: '#fafafa',
                bulletColor: '#fafafa',
                onMoveStart: function(e) {
                    console.log(event.target);
                },
                onMoving: function() {
                    console.log(event.target);
                },
                onMoveEnd: function() {
                    console.log(event.target);
                },
            });
        } catch (e) {

        }


        $('.slickTestimonials').slick({
            dots: true,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: 3,
            infinite: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

    };


})();
