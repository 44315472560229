/**
 * Main JS
 *
 */

import Swal from 'sweetalert2';

window.FrontMain = new (function() {

    const that = this;

    let onScroll = function(){
        $(window).scroll(function () {
            if ($(window).scrollTop() > 30)
                $('header').addClass('stick')
            else
                $('header').removeClass('stick')
        })
    }

    let menu = function(){
        $('body').toggleClass('noScroll');
    };

    this.init = function (){

        $('.menuMb .hamburger').on('click', menu);

        $('.wrapOur').slick({
            dots: true,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            infinite: false,
            autoplay: true
        });

    };

    /**
     * Service section
     */
    this.services = function () {

        $('#servicesTabs li').on('click', function (){
            $('#servicesTabs li').removeClass('current');
            $(this).addClass('current');
            $('.backElement.obra-ichep').attr('style', 'display:none !important' );
            $('.backElement.ing-ichep').attr('style', 'display:none !important' );

            let tabID = $(this).find('a').attr('href');

            if(tabID == '#generalConstructor') {
                $('.backElement.obra-ichep').removeAttr('style');
                $('.backElement.ing-ichep').removeAttr('style');
            }
            if(tabID == '#projectArq' || tabID == '#ingDetail') {
                $('.backElement.ing-ichep').removeAttr('style');
            }
        });

    };

    /**
     * Projects section
     */
    this.projects = function () {

        $('#projectsTabs li').on('click', function (){
            $('#projectsTabs li').removeClass('current');
            $(this).addClass('current');

            let tabID = $(this).find('a').attr('href');
            if(tabID != '#generalConstructor') {
                $('.backElement.obra-ichep').attr('style', 'display:none !important' );
            } else {
                $('.backElement.obra-ichep').removeAttr('style');
            }
            if(tabID == '#projectArq' || tabID == '#ingDetail') {
                $('.backElement.ing-ichep').attr('style', 'display:none !important' );
            } else {
                $('.backElement.ing-ichep').removeAttr('style');
            }

        });

    };

    /**
     * Contact section
     */
    this.contact = function () {



    };

})();
