/**
 * Us JS
 *
 */

import Swal from 'sweetalert2';

window.Us = new (function() {

    const that = this;

    this.init = function (){

        $('.timeline').slick({
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            infinite: false
        });
        $('.timeline-years').slick({
            dots: false,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: 5,
            infinite: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });

        $('.timeline-years .item').on('click', that.showSpecificYear);

        $('.timeline').on('afterChange', function(event, slick, currentSlide, nextSlide){
            let description = $('.timeline-years .item.slider-'+currentSlide).data('description');
            $('.timeline-years .item.slider-'+currentSlide).trigger('click');
            $('.timeline-years').slick('slickGoTo', currentSlide);

            $('.wrap-timeline-description p').html(description);
        });
    };


    this.showSpecificYear = function () {
        $('.timeline-years .item').removeClass('active');
        $(this).addClass('active');

        var slideno = $(this).data('slider');
        $('.timeline').slick('slickGoTo', slideno);
    };

})();
